import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { Good } from 'src/app/store/good/good.model';
import { Network } from 'src/app/store/network/network.model';
import { Person } from 'src/app/store/person/person.model';
import { User } from 'src/app/store/user/user.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { createFact } from 'src/app/store/fact/fact.actions';
import { AddGoodModalComponent } from './components/add-good-modal/add-good-modal.component';
import { AddPerpetratorModalComponent } from './components/add-perpetrator-modal/add-perpetrator-modal.component';
import { AddProtagonistModalComponent } from './components/add-protagonist-modal/add-protagonist-modal.component';
import { AddVictimModalComponent } from './components/add-victim-modal/add-victim-modal.component';
import { FactService } from 'src/app/core/services/fact.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Validator } from 'src/app/core/utils/utils.validator';

@Component({
  selector: 'app-fact-create',
  templateUrl: './fact-create.page.html',
})
export class FactCreatePage implements OnInit {
  currentUser: User;
  networks: Network[];
  towns: any[];
  transports: string[];
  step: number;
  steps: number;
  percent: number;
  persons: Person[];
  perpetrators: Person[];
  victims: Person[];
  protagonists: Person[];
  goods: Good[];
  today: Date = new Date();
  step1Submitted = false;
  step2Submitted = false;
  step3Submitted = false;
  step4Submitted = false;
  step1Form: UntypedFormGroup;
  step2Form: UntypedFormGroup;
  step3Form: UntypedFormGroup;
  step4Form: UntypedFormGroup;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  selectedNomenclature: string;
  selectedNomenclatureText: string;
  selectedNomenclatureExample: string;
  nomenclature: any;
  previousType: string;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private factService: FactService,
    private storageService: StorageService,
    private modalController: ModalController,
    private notificationService: NotificationService,
  ) {
    this.step = 1;
    this.steps = 4;
    this.persons = [];
    this.perpetrators = [];
    this.victims = [];
    this.protagonists = [];
    this.goods = [];
    this.refreshPercent();
    this.nomenclature = this.factService.getNomenclature();
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.networks = this.currentUser.networks;
    this.step1Form = this.formBuilder.group({
      date: [formatDate(new Date(), 'yyyy-MM-dd', 'fr'), [
        Validator.required('La date du fait est obligatoire.'),
        Validator.lessThanTodayDate("La date du fait ne peut pas être postérieure à la date d'aujourd'hui."),
      ]],
      time: [formatDate(new Date(),'HH:mm', 'fr'), [
        Validator.required("L'heure du fait est obligatoire."),
      ]],
      networkId: ['', [
        Validator.required('Le réseau est obligatoire.'),
      ]],
      town: ['', [
        Validator.required('La commune est obligatoire.'),
      ]],
      transport: ['', [
        Validator.required('Le mode de transport est obligatoire.'),
      ]],
      stop: [''],
      line: [''],
      priorityZone: [null]
    },
    {
      validator: Validator.lessThanTodayTime("L'heure du fait ne peut pas être postérieure à l'heure actuelle."),
    });

    this.step2Form = this.formBuilder.group({
      nomenclature: ['', [
        Validator.required("Le type de fait est obligatoire."),
      ]],
      type1: [''],
      type2: [''],
      type3: [''],
      type4: [''],
      serviceImpact: [null],
      offerModification: [null],
      departmentIntervention: [null],
      policeIntervention: [null],
      emergencyIntervention: [null],
      complaint: [null],
      weapon: [null],
      cause: ['Inconnue', [
        Validator.required("La cause est obligatoire."),
      ]],
    });

    this.step3Form = this.formBuilder.group({
      persons: [this.persons],
    });

    this.step4Form = this.formBuilder.group({
      goods: [this.goods],
    });

    if (this.networks.length) {
      const networkId = this.networks[0].id;
      this.step1Form.controls.networkId.setValue(networkId);
      this.onChangeNetwork(networkId);
    }
  }
  
  onNext() {
    if (this.step === 1) {
      this.step1Submitted = true;
      if (!this.step1Form.valid) {
        this.showErrors();
        return false;
      }
    }

    if (this.step === 2) {
      this.step2Submitted = true;
      if (!this.step2Form.valid) {
        this.showErrors();
        return false;
      }
    }

    if (this.step < 4){
      this.step += 1;
      this.refreshPercent();
      this.content.scrollToTop();
    } else {
      this.store.dispatch(createFact({
        ...this.step1Form.value,
        ...this.step2Form.value,
        ...this.step3Form.value,
        ...this.step4Form.value,
      }));
    }
  }

  onPrevious() {
    if (this.step > 1){
      this.step -= 1;
      this.refreshPercent();
      this.content.scrollToTop();
    }
  }

  onChangeNetwork(networkId: number) {
    this.refreshTowns(networkId);
    this.refreshTransports(networkId);
  }

  onChangeType(type: HTMLElement) {
    const isEnd = type.dataset['end'];
    if (isEnd) {
      const nomenclature = this.getNomenclature();
      if (nomenclature) {
        this.step2Form.controls.nomenclature.setValue(nomenclature);
        this.selectedNomenclature = nomenclature;
        this.selectedNomenclatureText = this.nomenclature[nomenclature].label;
        this.selectedNomenclatureExample = this.nomenclature[nomenclature].example;
      } else {
        this.resetType();
      }
    }
  }

  getNomenclature() {
    let nomenclature = '';
    const type2 = this.step2Form.controls.type2.value;
    const type3 = this.step2Form.controls.type3.value;
    const type4 = this.step2Form.controls.type4.value;
    if (type4) {
      nomenclature = type4 === '#' ? type3 : type4;
    } else if (type3) {
      nomenclature = type3 === '#' ? type2 : type3;
    } else if (type2) {
      nomenclature = type2 === '#' ? undefined : type2;
    }
    return nomenclature;
  }

  onBackButtonClick() {
    const type1 = this.step2Form.controls.type1.value;
    const type2 = this.step2Form.controls.type2.value;
    const type3 = this.step2Form.controls.type3.value;
    const type4 = this.step2Form.controls.type4.value;
    if (type4) {
      this.previousType = type4;
      this.step2Form.controls.type4.setValue('');
    } else if (type3) {
      this.previousType = type3;
      this.step2Form.controls.type3.setValue('');
    } else if (type2) {
      this.previousType = type2;
      this.step2Form.controls.type2.setValue('');
    } else if (type1) {
      this.previousType = type1;
      this.step2Form.controls.type1.setValue('');
    }
    this.resetType();
  }

  async onAddPerpetratorButtonClick() {
    const modal = await this.modalController.create({
      component: AddPerpetratorModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const perpetrator = data['data'] as Person;
      if (perpetrator) {
        this.perpetrators.push(perpetrator);
        this.persons.push(perpetrator);
        this.step3Form.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemovePerpetratorButtonClick(perpetratorId) {
    if (!perpetratorId) {
      return;
    }

    this.perpetrators = this.perpetrators.filter((_perpetrator) => _perpetrator.id != perpetratorId);
    this.persons = this.persons.filter((_person) => _person.id != perpetratorId);
    this.step3Form.controls.persons.setValue(this.persons);
  }

    async onAddVictimButtonClick() {
    const modal = await this.modalController.create({
      component: AddVictimModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const victim = data['data'] as Person;
      if (victim) {
        this.victims.push(victim);
        this.persons.push(victim);
        this.step3Form.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemoveVictimButtonClick(victimId) {
    if (!victimId) {
      return;
    }

    this.victims = this.victims.filter((_victim) => _victim.id != victimId);
    this.persons = this.persons.filter((_person) => _person.id != victimId);
    this.step3Form.controls.persons.setValue(this.persons);
  }

  async onAddProtagonistButtonClick() {
    const modal = await this.modalController.create({
      component: AddProtagonistModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const protagonist = data['data'] as Person;
      if (protagonist) {
        this.protagonists.push(protagonist);
        this.persons.push(protagonist);
        this.step3Form.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemoveProtagonistButtonClick(protagonistId) {
    if (!protagonistId) {
      return;
    }

    this.protagonists = this.protagonists.filter((_protagonist) => _protagonist.id != protagonistId);
    this.persons = this.persons.filter((_person) => _person.id != protagonistId);
    this.step3Form.controls.persons.setValue(this.persons);
  }

  async onAddGoodButtonClick() {
    const modal = await this.modalController.create({
      component: AddGoodModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const good = data['data'] as Good;
      if (good) {
        this.goods.push(good);
        this.step4Form.controls.goods.setValue(this.goods);
      }
    });

    await modal.present();
  }

  onRemoveGoodButtonClick(goodId) {
    if (!goodId) {
      return;
    }

    this.goods = this.goods.filter((_good) => _good.id != goodId);
    this.step4Form.controls.goods.setValue(this.goods);
  }

  resetType() {
    this.step2Form.controls.nomenclature.setValue('');
    this.selectedNomenclature = undefined;
    this.selectedNomenclatureText = undefined;
  }

  refreshTowns(networkId) {
    this.towns = this.currentUser.networks
      .filter((_network) => _network.id == networkId)
      .map((_network) => _network.towns ?? [])
      .reduce((_town) => _town)
      .sort((_townA, _townB) => _townA.localeCompare(_townB))
      .map((_town) => {
        return {
          name: _town.split('[')[0].trim(),
          value: _town
        }
      });

    this.towns?.length
      ? this.step1Form.controls.town.setValue(this.towns[0].value)
      : this.step1Form.controls.town.reset();
  }

  refreshTransports(networkId) {
    this.transports = this.currentUser.networks
      .filter((_network) => _network.id == networkId)
      .map((_network) => _network.transports ?? [])
      .reduce((_transport) => _transport)
      .sort((_transportA, _transportB) => _transportA.localeCompare(_transportB));

    this.transports?.length
      ? this.step1Form.controls.transport.setValue(this.transports[0])
      : this.step1Form.controls.transport.reset();
  }

  conditionalValidator(predicate, validator) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl); 
      }
      return null;
    })
  }

  refreshPercent() {
    this.percent = this.step / this.steps * 100;
  }

  private showErrors() {
    let group: UntypedFormGroup;
    switch(this.step) {
      case 1:
        group = this.step1Form;
        break;
      case 2:
        group = this.step2Form;
        break;
    }

    const controlErrors = Object.values(group.controls)
      .filter((_control: UntypedFormControl) => _control.errors)
      .flatMap((_control: UntypedFormControl) => Object.values( _control.errors));
    const groupErrors = group.errors
      ?  Object.values(group.errors)
      : [];
    const errorMessage = controlErrors
      .concat(groupErrors)
      .join('<br/>');

    if (errorMessage) {
      this.notificationService.show({
        type: 'ERROR',
        date: new Date(),
        message: errorMessage,
      });
    }
  }

  onTownClick() {
    setTimeout(()=> {
      document.getElementById("networkId").scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "end"
      });
    }, 50);
  }

}
