<ion-content>
<main role="main">
  <div class="fr-container--fluid fr-mb-2w fr-mb-md-0 sticky">
    <header role="banner" class="fr-header" aria-label="En-tête">
      <div class="fr-container">
        <nav class="fr-nav submenu submenu--top" role="navigation" aria-label="Menu secondaire"
          data-fr-js-navigation="true">
          <div class="fr-grid-row fr-mx-n1w">
            <div class="fr-col-12 fr-px-1w">
              <div class="submenu-logo">
                <div class="fr-col-lg-2">
                  <div class="fr-text--bold fr-py-1w fr-py-md-0 fr-text--lead">Saisie d'un fait</div>
                </div>
                <div class="fr-col">
                  <div class="fr-label fr-text--sm fr-text--right" for="file">Étape {{ this.step }} sur 4</div>
                </div>
              </div>
            </div>
            <div class="fr-col-12 fr-px-1w">
              <div class="fr-input-group">
                <progress class="fr-progress" id="file" max="100" value="{{ this.percent }}">{{ this.percent }}%</progress>
              </div>
            </div>
          </div>
          <div class="fr-grid-row fr-mx-n1w">
            <div class="fr-col fr-px-1w">
              <a class="fr-nav__link"
                [attr.aria-current]="step === 1 ? 'true' : null"
                aria-disabled="false">
                <span class="submenu-entry">Lieu</span>
                <span class="submenu-entry2">Lieu</span>
              </a>
            </div>
            <div class="fr-col fr-px-1w">
              <a class="fr-nav__link"
                [attr.aria-current]="step === 2 ? 'true' : null"
                [attr.aria-disabled]="step <= 2 ? 'true' : null">
                <span class="submenu-entry">Faits</span>
                <span class="submenu-entry2">Faits</span>
              </a>
            </div>
            <div class="fr-col fr-px-1w">
              <a class="fr-nav__link" 
                [attr.aria-current]="step === 3 ? 'true' : null"
                [attr.aria-disabled]="step <= 3 ? 'true' : null">
                <span class="submenu-entry">Personnes impliquées</span>
                <span class="submenu-entry2">Personnes</span>
              </a>
            </div>
            <div class="fr-col fr-px-1w">
              <a class="fr-nav__link"
                [attr.aria-current]="step === 4 ? 'true' : null"
                [attr.aria-disabled]="step <= 4 ? 'true' : null">
                <span class="submenu-entry">Biens impactés</span>
                <span class="submenu-entry2">Biens</span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>

  <!-- STEP 1 -->
  <div [hidden]="step !== 1" class="fr-container">
    <div class="fr-grid-row overflow-hidden">
      <div class="fr-col">
        <div class="fr-container--fluid">
          <form *ngIf="step1Form" [formGroup]="step1Form" novalidate>
            <section class="fr-grid-row fr-mt-2w fr-mb-20v">
              <div class="fr-col">

                <h3 class="fr-text--lg fr-mb-4v">Date et heure</h3>
                <div class="fr-input-group">
                  <label class="fr-label" for="date">Date (de commission, constatation ou signalement)*</label>
                  <input class="fr-input" type="date" id="date" name="date" formControlName="date" max="{{ today | date:'yyyy-MM-dd'}}">
                  <p *ngIf="step1Submitted && step1Form.controls?.date?.errors?.required" class="fr-error-text">
                    La date du fait est obligatoire.
                  </p>
                  <p *ngIf="step1Submitted && step1Form.controls?.date?.errors?.lessThanTodayDate" class="fr-error-text">
                    La date du fait ne peut pas être postérieure à la date d'aujourd'hui.
                  </p>
                </div>

                <div class="fr-input-group">
                  <label class="fr-label" for="time">Heure (de commission, constatation ou signalement)*</label>
                  <input class="fr-input" type="time" id="time" name="time" formControlName="time">
                  <p *ngIf="step1Submitted && step1Form.controls?.time?.errors?.required" class="fr-error-text">
                    L'heure du fait est obligatoire.
                  </p>
                  <p *ngIf="step1Submitted && step1Form.errors?.lessThanTodayTime" class="fr-error-text">
                    L'heure du fait ne peut pas être postérieure à l'heure actuelle.
                  </p>
                </div>

                <h3 class="fr-text--lg fr-mb-4v">Lieu</h3>

                <div class="fr-select-group">
                  <label class="fr-label" for="networkId">Réseau*</label>
                  <select (change)="onChangeNetwork($event.target.value)" class="fr-select" id="networkId" name="networkId" formControlName="networkId">
                    <option value="" selected disabled hidden>Sélectionnez un réseau</option>
                    <option *ngFor="let network of networks;" [value]="network.id">
                      {{ network.name }}
                    </option>
                  </select>
                  <p *ngIf="step1Submitted && step1Form.controls?.network?.errors?.required" class="fr-error-text">
                    Le réseau est obligatoire.
                  </p>
                </div>

                <div [hidden]="!towns" class="fr-select-group">
                  <label class="fr-label" for="town">Commune*</label>
                  <ng-select id="town" name="town" formControlName="town" (click)="onTownClick()" [items]="towns" bindLabel="name" bindValue="value"
                    loadingText="Chargement..." clearAllText="Tout retirer" notFoundText="Aucune commune trouvée."
                    typeToSearchText="Veuillez entrer au moins 2 caractères"></ng-select>
                  <p *ngIf="step1Submitted && step1Form.controls?.town?.errors?.required" class="fr-error-text">
                    La commune est obligatoire.
                  </p>
                </div>

                <div [hidden]="!transports" class="fr-select-group">
                  <label class="fr-label" for="transport">Mode de transport*</label>
                  <select class="fr-select" id="transport" name="transport" formControlName="transport">
                    <option *ngFor="let transport of transports;" [value]="transport">
                      {{ transport }}
                    </option>
                  </select>
                  <p *ngIf="step1Submitted && step1Form.controls?.town?.errors?.required" class="fr-error-text">
                    Le mode de transport est obligatoire.
                  </p>
                </div>

                <div class="fr-input-group">
                  <label class="fr-label" for="stop">Station / Arrêt</label>
                  <input class="fr-input" type="text" id="stop" name="stop" formControlName="stop">
                </div>

                <div class="fr-input-group">
                  <label class="fr-label" for="line">Ligne</label>
                  <input class="fr-input" type="text" id="line" name="line" formControlName="line">
                </div>

              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- STEP 2 -->

  <div [hidden]="step !== 2" class="fr-container">
    <div class="fr-grid-row overflow-hidden">
      <div id="content" class="fr-col fr-pl-4v">
        <div class="fr-container--fluid">
          <form *ngIf="step2Form" [formGroup]="step2Form" novalidate>
            <section class="fr-grid-row fr-mt-2w fr-mb-20v">
              <div class="fr-col">
                <h3 class="fr-text--lg fr-mb-4v">Famille de faits</h3>

                <!-- LEVEL 1 -->

                <div [hidden]="step2Form.controls?.type1.value" class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type1-1" name="type1" value="1"
                          formControlName="type1" [class.checked]="previousType == '1'">
                        <label class="fr-label " for="type1-1">{{ nomenclature['1'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['1'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type1-2" name="type1" value="2"
                          formControlName="type1" [class.checked]="previousType == '2'">
                        <label class="fr-label" for="type1-2">{{ nomenclature['2'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['2'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type1-3" name="type1" value="3"
                          formControlName="type1" [class.checked]="previousType == '3'">
                        <label class="fr-label" for="type1-3">{{ nomenclature['3'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['3'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type1-4" name="type1" value="4"
                          formControlName="type1" [class.checked]="previousType == '4'">
                        <label class="fr-label" for="type1-4">{{ nomenclature['4'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['4'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- LEVEL 2 -->

                <div [hidden]="step2Form.controls?.type2.value" *ngIf="step2Form.controls?.type1.value === '1'" class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-A" name="type2" value="A"
                        formControlName="type2" [class.checked]="previousType == 'A'">
                        <label class="fr-label" for="type2-A">{{ nomenclature['A'].label }}</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-B" name="type2" value="B"
                        formControlName="type2" [class.checked]="previousType == 'B'">
                        <label class="fr-label" for="type2-B">{{ nomenclature['B'].label }}</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-C" name="type2" value="C"
                        formControlName="type2" [class.checked]="previousType == 'C'" data-end="true">
                        <label class="fr-label" for="type2-C">{{ nomenclature['C'].label }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type2.value" *ngIf="step2Form.controls?.type1.value === '2'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-D2" name="type2" value="D2"
                          formControlName="type2" [class.checked]="previousType == 'D2'">
                        <label class="fr-label fr-pb-0" for="type2-D2">{{ nomenclature['D2'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D2'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-D1" name="type2" value="D1"
                          formControlName="type2" [class.checked]="previousType == 'D1'">
                        <label class="fr-label fr-pb-0" for="type2-D1">{{ nomenclature['D1'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D1'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type2.value" *ngIf="step2Form.controls?.type1.value === '3'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-E1" name="type2" value="E1"
                          formControlName="type2" [class.checked]="previousType == 'E1'">
                        <label class="fr-label fr-pb-0" for="type2-E1">{{ nomenclature['E1'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['E1'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-E2" name="type2" value="E2"
                          formControlName="type2" [class.checked]="previousType == 'E2'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type2-E2">{{ nomenclature['E2'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['E2'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-E3" name="type2" value="E3"
                          formControlName="type2" [class.checked]="previousType == 'E3'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type2-E3">{{ nomenclature['E3'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['E3'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type2.value" *ngIf="step2Form.controls?.type1.value === '4'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-F1" name="type2" value="F1"
                          formControlName="type2" [class.checked]="previousType == 'F1'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type2-F1">{{ nomenclature['F1'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['F1'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-F2" name="type2" value="F2"
                          formControlName="type2" [class.checked]="previousType == 'F2'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type2-F2">{{ nomenclature['F2'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['F2'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type2-F3" name="type2" value="F3"
                          formControlName="type2" [class.checked]="previousType == 'F3'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type2-F3">{{ nomenclature['F3'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['F3'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- LEVEL 3 -->

                <div [hidden]="step2Form.controls?.type3.value" *ngIf="step2Form.controls?.type2.value === 'A'" class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-A1" name="type3" value="A1"
                        formControlName="type3" [class.checked]="previousType == 'A1'">
                        <label class="fr-label" for="type3-A1">{{ nomenclature['A1'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['A1'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-A2" name="type3" value="A2"
                        formControlName="type3" [class.checked]="previousType == 'A2'" data-end="true">
                        <label class="fr-label" for="type3-A2">{{ nomenclature['A2'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['A2'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type3.value" *ngIf="step2Form.controls?.type2.value === 'B'" class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-B1" name="type3" value="B1"
                          formControlName="type3" [class.checked]="previousType == 'B1'" data-end="true">
                        <label class="fr-label" for="type3-B1">{{ nomenclature['B1'].label }}</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-B2" name="type3" value="B2"
                          formControlName="type3" [class.checked]="previousType == 'B2'" data-end="true">
                        <label class="fr-label" for="type3-B2">{{ nomenclature['B2'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['B2'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-B3" name="type3" value="B3"
                          formControlName="type3" [class.checked]="previousType == 'B3'">
                        <label class="fr-label" for="type3-B3">{{ nomenclature['B3'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['B3'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type3.value" *ngIf="step2Form.controls?.type2.value === 'D2'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-D21" name="type3" value="D21"
                          formControlName="type3" [class.checked]="previousType == 'D21'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-D21">{{ nomenclature['D21'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D21'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-D22" name="type3" value="D22"
                          formControlName="type3" [class.checked]="previousType == 'D22'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-D22">{{ nomenclature['D22'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D22'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-D23" name="type3" value="D23"
                          formControlName="type3" [class.checked]="previousType == 'D23'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-D23">{{ nomenclature['D23'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D23'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-#" name="type3" value="#"
                          formControlName="type3" [class.checked]="previousType == '#'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-#">{{ nomenclature['D2'].label2 }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D2'].example2 }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type3.value" *ngIf="step2Form.controls?.type2.value === 'D1'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-D3" name="type3" value="D3"
                          formControlName="type3" [class.checked]="previousType == 'D3'" data-end="true">
                        <label class="fr-label" for="type3-D3">{{ nomenclature['D3'].label }}</label>
                          <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D3'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-#" name="type3" value="#"
                          formControlName="type3" [class.checked]="previousType == '#'" data-end="true">
                        <label class="fr-label" for="type3-#">{{ nomenclature['D1'].label }}</label>
                          <p class="fr-hint-text fr-ml-8v">{{ nomenclature['D1'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type3.value" *ngIf="step2Form.controls?.type2.value === 'E1'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-#" name="type3" value="#"
                          formControlName="type3" [class.checked]="previousType == '#'"  data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-#">{{ nomenclature['E1'].label2 }}</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-E11" name="type3" value="E11"
                          formControlName="type3" [class.checked]="previousType == 'E11'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-E11">{{ nomenclature['E11'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['E11'].example }}</p>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type3-E12" name="type3" value="E12"
                          formControlName="type3" [class.checked]="previousType == 'E12'" data-end="true">
                        <label class="fr-label fr-pb-0" for="type3-E12">{{ nomenclature['E12'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['E12'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- LEVEL 4 -->

                <div [hidden]="step2Form.controls?.type4.value" *ngIf="step2Form.controls?.type3.value === 'A1'" class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type4-#" name="type4" value="#"
                        formControlName="type4" [class.checked]="previousType == '#'" data-end="true">
                        <label class="fr-label" for="type4-#">{{ nomenclature['A1'].label2 }}</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type4-A11" name="type4" value="A11"
                          formControlName="type4" [class.checked]="previousType == 'A11'" data-end="true">
                        <label class="fr-label" for="type4-A11">{{ nomenclature['A11'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['A11'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="step2Form.controls?.type4.value" *ngIf="step2Form.controls?.type3.value === 'B3'"
                  class="fr-form-group fr-mb-3w">
                  <div class="fr-fieldset">
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type4-#" name="type4" value="#"
                          formControlName="type4" [class.checked]="previousType == '#'"  data-end="true">
                        <label class="fr-label" for="type4-#">{{ nomenclature['B3'].label2 }}</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input (change)="onChangeType($event.target)" type="radio" id="type4-B31" name="type4" value="B31"
                          formControlName="type4" [class.checked]="previousType == 'B31'" data-end="true">
                        <label class="fr-label" for="type4-B31">{{ nomenclature['B31'].label }}</label>
                        <p class="fr-hint-text fr-ml-8v">{{ nomenclature['B31'].example }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- RESULT -->

                <div *ngIf="selectedNomenclature" class="fr-form-group fr-mb-2w">
                  <p class="fr-mb-2v">
                    <em _ngcontent-rcu-c157="" class="fr-icon-success fr-icon-success-fill"></em>
                    Le type de fait a bien été sélectionné :
                  </p>
                  <div class="fr-fieldset">
                    <div class="fr-alert fr-alert--info fr-alert--sm fr-alert--clear">
                      <p>{{ selectedNomenclatureText }}</p>
                      <p *ngIf="selectedNomenclatureExample" class="fr-hint-text fr-mb-1v">{{ selectedNomenclatureExample }}</p>
                    </div>
                  </div>
                </div>

                <ul *ngIf="step2Form.controls?.type1.value" class="fr-btns-group fr-btns-group--inline fr-btns-group--sm">
                  <li>
                    <button (click)="onBackButtonClick()" class="fr-btn fr-btn--tertiary">Retour</button>
                  </li>
                </ul>

                <div *ngIf="step2Submitted && step2Form.controls?.nomenclature?.errors?.required" class="fr-input-group">
                  <p class="fr-error-text">
                    Le type de fait est obligatoire.
                  </p>
                </div>
    
                <h3 class="fr-text--lg fr-mb-4v">Conséquences et moyen</h3>

                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular fr-mb-1v">
                      Impact sur le service
                    </legend>
                    <p class="fr-hint-text">Retard, suppression de véhicule…</p>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="serviceImpact-2" name="serviceImpact" formControlName="serviceImpact" [value]="true">
                        <label class="fr-label" for="serviceImpact-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="serviceImpact-3" name="serviceImpact" formControlName="serviceImpact" [value]="false">
                        <label class="fr-label" for="serviceImpact-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="serviceImpact-1" name="serviceImpact" formControlName="serviceImpact" [value]="null">
                        <label class="fr-label" for="serviceImpact-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular fr-mb-1v">
                      Modification de l'offre
                    </legend>
                    <p class="fr-hint-text">Contournement, arrêt de ligne…</p>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="offerModification-2" name="offerModification" formControlName="offerModification"
                          [value]="true">
                        <label class="fr-label" for="offerModification-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="offerModification-3" name="offerModification" formControlName="offerModification"
                          [value]="false">
                        <label class="fr-label" for="offerModification-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="offerModification-1" name="offerModification" formControlName="offerModification"
                          [value]="null">
                        <label class="fr-label" for="offerModification-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular fr-mb-1v">
                      Intervention des services de sécurité / Prévention du réseau
                    </legend>
                    <p class="fr-hint-text">Agents, prestataires…</p>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="departmentIntervention-2" name="departmentIntervention"
                          formControlName="departmentIntervention" [value]="true">
                        <label class="fr-label" for="departmentIntervention-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="departmentIntervention-3" name="departmentIntervention"
                          formControlName="departmentIntervention" [value]="false">
                        <label class="fr-label" for="departmentIntervention-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="departmentIntervention-1" name="departmentIntervention"
                          formControlName="departmentIntervention" [value]="null">
                        <label class="fr-label" for="departmentIntervention-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular">
                      Intervention des forces de l'ordre
                    </legend>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="policeIntervention-2" name="policeIntervention"
                          formControlName="policeIntervention" [value]="true">
                        <label class="fr-label" for="policeIntervention-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="policeIntervention-3" name="policeIntervention"
                          formControlName="policeIntervention" [value]="false">
                        <label class="fr-label" for="policeIntervention-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="policeIntervention-1" name="policeIntervention"
                          formControlName="policeIntervention" [value]="null">
                        <label class="fr-label" for="policeIntervention-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular">
                      Intervention des secours
                    </legend>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="emergencyIntervention-2" name="emergencyIntervention"
                          formControlName="emergencyIntervention" [value]="true">
                        <label class="fr-label" for="emergencyIntervention-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="emergencyIntervention-3" name="emergencyIntervention"
                          formControlName="emergencyIntervention" [value]="false">
                        <label class="fr-label" for="emergencyIntervention-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="emergencyIntervention-1" name="emergencyIntervention"
                          formControlName="emergencyIntervention" [value]="null">
                        <label class="fr-label" for="emergencyIntervention-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular">
                      Dépôt d'une plainte
                    </legend>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="complaint-2" name="complaint"
                          formControlName="complaint" [value]="true">
                        <label class="fr-label" for="complaint-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="complaint-3" name="complaint"
                          formControlName="complaint" [value]="false">
                        <label class="fr-label" for="complaint-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="complaint-1" name="complaint"
                          formControlName="complaint" [value]="null">
                        <label class="fr-label" for="complaint-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fr-form-group">
                  <div class="fr-fieldset fr-fieldset--inline">
                    <legend class="fr-fieldset__legend fr-text--regular fr-mb-1v">
                      Fait commis avec une arme
                    </legend>
                    <p class="fr-hint-text">Armes à feu, blanche, par destination, animal…</p>
                    <div class="fr-fieldset__content">
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="weapon-2" name="weapon" formControlName="weapon" [value]="true">
                        <label class="fr-label" for="weapon-2">Oui</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="weapon-3" name="weapon" formControlName="weapon" [value]="false">
                        <label class="fr-label" for="weapon-3">Non</label>
                      </div>
                      <div class="fr-radio-group fr-radio-group--sm">
                        <input type="radio" id="weapon-1" name="weapon" formControlName="weapon" [value]="null">
                        <label class="fr-label" for="weapon-1">Je ne sais pas</label>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 class="fr-text--lg fr-mb-4v">Cause (1 réponse possible)</h3>
                <div class="fr-select-group fr-mb-8v">
                  <select class="fr-select" id="cause" name="cause" formControlName="cause">
                    <option value="Inconnue">Je ne sais pas</option>
                    <option value="Différend de circulation">Différend de circulation</option>
                    <option value="Ébriété">Ébriété</option>
                    <option value="Incident de contrôle">Incident de contrôle</option>
                    <option value="Interposition / Assistance">Interposition / Assistance</option>
                    <option value="Problème tarifaire">Problème tarifaire</option>
                    <option value="Rappel du règlement">Rappel du règlement</option>
                    <option value="Représailles">Représailles</option>
                    <option value="Trouble du comportement">Trouble du comportement</option>
                    <option value="Violence urbaine">Violence urbaine</option>
                    <option value="Autre">Autre</option>
                  </select>
                  <p *ngIf="step1Submitted && step2Form.controls?.cause?.errors?.required" class="fr-error-text">
                    La cause est obligatoire.
                  </p>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- STEP 3 -->

  <div [hidden]="step !== 3" class="fr-container">
    <div class="fr-grid-row overflow-hidden">
      <div id="content" class="fr-col fr-pl-4v">
        <div class="fr-container--fluid">

          <section class="fr-grid-row fr-mt-2w fr-mb-4v">
            <div class="fr-col-12">
              <h3 class="fr-mb-4v fr-text--lg fr-mb-4v">Auteur(s)</h3>
              <div class="fr-container fr-container--highlight">
                <div class="fr-grid-row fr-py-4v">
                  <p *ngIf="!perpetrators.length" class="fr-mb-3v fr-px-10v fr-text--center fr-text--sm">Aucun auteur n'a été ajouté pour le moment.</p>
                  <div class="fr-col-12">
                    <button (click)="onAddPerpetratorButtonClick()" [class.fr-btn--secondary]="perpetrators.length"
                      class="fr-btn fr-btn--full fr-fi-add-line fr-btn--sm fr-btn--icon-left">
                      Ajouter
                    </button>
                  </div>
                  <div *ngFor="let perpetrator of perpetrators" class="fr-col-12 fr-mt-4v">
                    <div class="fr-tile fr-tile--horizontal">
                      <div class="fr-tile__body">
                        <button (click)="onRemovePerpetratorButtonClick(perpetrator.id)"
                          class="fr-btn--close fr-btn fr-tile__body--close fr-icon-delete-line"></button>
                        <p class="fr-tile__desc"><strong>Qualité : </strong>{{ perpetrator.quality }}</p>
                        <p class="fr-tile__desc"><strong>Fonction : </strong>{{ perpetrator.function }}</p>
                        <p class="fr-tile__desc"><strong>Genre : </strong>{{ perpetrator.gender }}</p>
                        <p class="fr-tile__desc"><strong>Nombre : </strong>{{ perpetrator.count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="fr-grid-row fr-mt-2w fr-mb-4v">
            <div class="fr-col-12">
              <h3 class="fr-mb-4v fr-text--lg fr-mb-4v">Victime(s)</h3>
              <div class="fr-container fr-container--highlight">
                <div class="fr-grid-row fr-py-4v">
                  <p *ngIf="!victims.length" class="fr-mb-3v fr-px-10v fr-text--center fr-text--sm">Aucune victime n'a été ajoutée pour le moment.</p>
                  <div class="fr-col-12">
                    <button (click)="onAddVictimButtonClick()" [class.fr-btn--secondary]="victims.length"
                      class="fr-btn fr-btn--full fr-fi-add-line fr-btn--sm fr-btn--icon-left">
                      Ajouter
                    </button>
                  </div>
                  <div *ngFor="let victim of victims" class="fr-col-12 fr-mt-4v">
                    <div class="fr-tile fr-tile--horizontal">
                      <div class="fr-tile__body">
                        <button (click)="onRemoveVictimButtonClick(victim.id)"
                          class="fr-btn--close fr-btn fr-tile__body--close fr-icon-delete-line"></button>
                        <p class="fr-tile__desc"><strong>Qualité : </strong>{{ victim.quality }}</p>
                        <p class="fr-tile__desc"><strong>Fonction : </strong>{{ victim.function }}</p>
                        <p class="fr-tile__desc"><strong>Genre : </strong>{{ victim.gender }}</p>
                        <p class="fr-tile__desc"><strong>Nombre : </strong>{{ victim.count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="fr-grid-row fr-mt-2w fr-mb-24v">
            <div class="fr-col-12">
              <h3 class="fr-text--lg fr-mb-1v">Protagoniste(s)</h3>
              <p class="fr-hint-text fr-mb-2v">Auteurs et victimes non identifiés (ex: rixes)</p>
              <div class="fr-container fr-container--highlight">
                <div class="fr-grid-row fr-py-4v">
                  <p *ngIf="!protagonists.length" class="fr-mb-4v fr-px-10v fr-text--center fr-text--sm">Aucun protagoniste n'a été ajouté pour le moment.</p>
                  <div class="fr-col-12">
                    <button (click)="onAddProtagonistButtonClick()" [class.fr-btn--secondary]="protagonists.length"
                      class="fr-btn fr-btn--full fr-btn--sm fr-fi-add-line fr-btn--icon-left">
                      Ajouter
                    </button>
                  </div>
                  <div *ngFor="let protagonist of protagonists" class="fr-col-12 fr-mt-4v">
                    <div class="fr-tile fr-tile--horizontal">
                      <div class="fr-tile__body">
                        <button (click)="onRemoveProtagonistButtonClick(protagonist.id)"
                          class="fr-btn--close fr-btn fr-tile__body--close fr-icon-delete-line"></button>
                        <p class="fr-tile__desc"><strong>Qualité : </strong>{{ protagonist.quality }}</p>
                        <p class="fr-tile__desc"><strong>Genre : </strong>{{ protagonist.gender }}</p>
                        <p class="fr-tile__desc"><strong>Nombre : </strong>{{ protagonist.count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>

  <!-- STEP 4 -->
  
  <div [hidden]="step !== 4" class="fr-container">
    <div class="fr-grid-row overflow-hidden">
      <div id="content" class="fr-col fr-pl-4v">
        <div class="fr-container--fluid">

          <section class="fr-grid-row fr-mt-2w fr-mb-4v">
            <div class="fr-col-12">
              <h3 class="fr-mb-4v fr-text--lg fr-mb-4v">Bien(s)</h3>
              <div class="fr-container fr-container--highlight">
                <div class="fr-grid-row fr-py-4v">
                  <p *ngIf="!goods.length" class="fr-mb-3v fr-px-10v fr-text--sm fr-text--center">Aucun bien n'a été ajouté pour le
                    moment.</p>
                  <div class="fr-col-12">
                    <button (click)="onAddGoodButtonClick()" [class.fr-btn--secondary]="goods.length"
                      class="fr-btn fr-btn--full fr-fi-add-line fr-btn--sm fr-btn--icon-left">
                      Ajouter
                    </button>
                  </div>
                  <div *ngFor="let good of goods" class="fr-col-12 fr-mt-4v">
                    <div class="fr-tile fr-tile--horizontal">
                      <div class="fr-tile__body">
                        <button (click)="onRemoveGoodButtonClick(good.id)"
                          class="fr-btn--close fr-btn fr-tile__body--close fr-icon-delete-line"></button>
                        <p class="fr-tile__desc"><strong>Qualité : </strong>{{ good.quality }}</p>
                        <p *ngIf="good.type" class="fr-tile__desc"><strong>Type : </strong>{{ good.type }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>


  <div class="fr-container--fluid fr-mb-md-0 fixed">
    <header role="banner" class="fr-header fr-header--bottom" aria-label="Pied de page">
      <div class="fr-container">
        <nav class="fr-nav submenu submenu--bottom" role="navigation" aria-label="Menu secondaire"
          data-fr-js-navigation="true">
          <div class="fr-grid-row fr-mx-n1w fr-py-2w">
            <div class="fr-col fr-px-1w">
              <button (click)="onPrevious()" class="fr-btn fr-btn--sm fr-btn--full" title="Précédent" [disabled]="step === 1">
                Précédent
              </button>
            </div>
            <div class="fr-col fr-px-1w">
              <a class="fr-btn fr-btn--full fr-btn--sm fr-btn--secondary" title="Annuler" replaceUrl="true" routerLink="/accueil">
                Annuler
              </a>
            </div>
            <div class="fr-col fr-px-1w">
              <button (click)="onNext()" class="fr-btn fr-btn--sm fr-btn--full" title="Suivant">
                {{ step === 4 ? "Enregistrer" : "Suivant" }}
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
</main>
</ion-content>
