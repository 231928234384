<form (ngSubmit)="login()" class="fr-mb-0">
  <button class="fr-btn full-width-button">Se connecter</button>
  <div class="fr-text--center">
    <ul class="fr-footer__bottom-list fr-pb-0">
      <li class="fr-footer__bottom-item">
        <a class="fr-footer__bottom-link" href="/accessibilite">Accessibilité</a>
      </li>
      <li class="fr-footer__bottom-item">
        <a class="fr-footer__bottom-link" href="/mentions-legales">Mentions légales</a>
      </li>
    </ul>
  </div>
</form>