<ion-content>
  <main class="modal" role="main">
    <div class="fr-container--fluid fr-mb-2w fr-mb-md-0 sticky">
      <header role="banner" class="fr-header" aria-label="En-tête">
        <div class="fr-container">
          <nav class="fr-nav submenu submenu--top" role="navigation" aria-label="Menu secondaire"
            data-fr-js-navigation="true">
            <div class="fr-grid-row fr-mx-n1w">
              <div class="fr-col-12 fr-px-1w">
                <div class="submenu-logo">
                  <div class="fr-col">
                    <div class="fr-text--bold fr-py-4v fr-text--lead">Filtres</div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
    <div class="fr-container" style="height: max-content">
      <div class="fr-grid-row overflow-hidden">
        <div class="fr-col">
          <div class="fr-container--fluid">
            <form *ngIf="filterForm" [formGroup]="filterForm" novalidate>
            
              <div class="fr-input-group">
                <label class="fr-label" for="id">
                  N° du fait
                </label>
                <input class="fr-input fr-text--sm" type="text" id="id" name="id" formControlName="id"
                  autocomplete="off">
                <p *ngIf="filterForm.controls?.id?.errors?.isInteger" class="fr-error-text">
                  Le n° du fait doit être un nombre entier.
                </p>
              </div>

              <div class="fr-select-group">
                <label class="fr-label" for="nomenclature">
                  Type de fait
                </label>
                <select class="fr-select" id="nomenclature" name="nomenclature" formControlName="nomenclature">
                  <option value="" selected>Tous</option>
                  <option *ngFor="let nomenclature of nomenclatures;" value="{{ nomenclature.value }}">{{ nomenclature.label }}</option>
                </select>
              </div>

              <div class="fr-select-group">
                <label class="fr-label" for="networkId">
                  Réseau
                </label>
                <select class="fr-select" id="networkId" name="networkId" formControlName="networkId">
                  <option value="" selected>Tous</option>
                  <option *ngFor="let network of networks;" value="{{ network.id }}">{{ network.name }}</option>
                </select>
              </div>
            
              <div class="fr-input-group">
                <label class="fr-label" for="startDate">
                  Date de début
                </label>
                <input class="fr-input fr-text--sm" type="date" id="startDate" name="startDate" formControlName="startDate"
                  autocomplete="off">
              </div>
              <div class="fr-input-group">
                <label class="fr-label" for="endDate">
                  Date de fin
                </label>
                <input class="fr-input fr-text--sm" type="date" id="endDate" name="endDate" formControlName="endDate"
                  autocomplete="off">
              </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="fr-container--fluid fr-mb-md-0 fixed">
      <header role="banner" class="fr-header--bottom" aria-label="Pied de page">
        <div class="fr-container">
          <nav class="fr-nav submenu submenu--bottom" aria-label="Menu d'actions">
            <div class="fr-grid-row fr-mx-n1w fr-py-2w">
              <div class="fr-col fr-px-1w">
                <button (click)="onDismiss()" class="fr-btn fr-btn--secondary fr-btn--sm fr-btn--full">Annuler</button>
              </div>
              <div class="fr-col fr-px-1w">
                <button (click)="onSubmit()" type="submit" class="fr-btn fr-btn--sm  fr-btn--full">Appliquer</button>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  </main>
</ion-content>