<ion-header>
<base href="/">
<header role="banner" class="fr-header">
  <div class="fr-header__body">
    <div class="fr-container">
      <div class="fr-header__body-row">
        <div class="fr-header__brand fr-enlarge-link">
          <div class="fr-header__brand-top">
            <div class="fr-header__logo">
              <p class="fr-logo">
                Ministère
                <br>chargé des
                <br>transports
              </p>
            </div>
            <div class="fr-header__operator">
              <img src="assets/isis-logo.svg" class="fr-responsive-img" style="max-width:9.0625rem;" alt="App'ISIS logo." />
            </div>
            <div class="fr-header__navbar">
              <button *ngIf="(isAuthenticated$ | async)" class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="MenuDialog"
                aria-haspopup="menu" title="Menu" data-fr-js-modal-button="true">
                Menu
              </button>
              <span *ngIf="!(isAuthenticated$ | async)" class="fr-version">{{ version }}</span>
            </div>
          </div>
          <div class="fr-header__service">
            <a href="/" title="Tableau de board d'App'ISIS">
              <h1 class="fr-header__service-title fr-text--center">App'ISIS</h1>
            </a>
          </div>
        </div>
        <div class="fr-header__tools">
          <div class="fr-header__tools-links">
            <ul *ngIf="(isAuthenticated$ | async)" class="fr-links-group">
              <li>
                <button class="fr-link fr-icon-user-line">{{ currentUser.name }}</button>
              </li>
              <li>
                <button id="AssistanceButton" (click)="assistance()" class="fr-link fr-icon-question-line" aria-controls="MenuDialog">Assistance</button>
              </li>
              <li>
                <button id="DeconnectionButton" (click)="logout()" class="fr-link fr-fi-lock-line deconnection-button">Se déconnecter</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fr-header__menu fr-modal" id="MenuDialog" aria-labelledby="fr-btn-menu-mobile-4">
    <div class="fr-container">
      <button class="fr-link--close fr-link" aria-controls="MenuDialog">Fermer</button>
      <div class="fr-header__menu-links"></div>
      <div class="fr-version--menu">{{ version }}</div>
      <nav class="fr-nav" id="navigation-869" role="navigation" aria-label="Menu principal">
        <ul  *ngIf="(isAuthenticated$ | async )" class="fr-nav__list">
        </ul>
      </nav>
    </div>
  </div>
</header>
</ion-header>