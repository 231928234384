import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FactQuery } from 'src/app/core/models/fact-query.model';
import { FactService } from 'src/app/core/services/fact.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Validator } from 'src/app/core/utils/utils.validator';
import { Network } from 'src/app/store/network/network.model';
import { User } from 'src/app/store/user/user.model';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
})
export class FilterModalComponent implements OnInit {
  @Input() currentQuery: FactQuery;
  filterForm: UntypedFormGroup;
  currentUser: User;
  networks = [];
  nomenclatures: any[];

  constructor(
    private storageService: StorageService,
    private factService: FactService,
    private formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    const userNetworks = this.currentUser.networks;
    userNetworks.sort((a: Network, b: Network) => a.name.localeCompare(b.name));
    this.networks = userNetworks;
    const nomenclatureDb = this.factService.getNomenclature();
    this.nomenclatures = Object.keys(nomenclatureDb)
      .filter((_key) => /^C|[A-Z].+$/.test(_key)) // NOSONAR
      .map((_key) => {
        return {
          label: nomenclatureDb[_key].label,
          value: _key
        }
      });

    this.filterForm = this.formBuilder.group({
      id: [this.currentQuery.id, [
        Validator.isInteger("Le n° du fait doit être un nombre entier."),
      ]],
      nomenclature: [this.currentQuery.nomenclature, []],
      networkId: [this.currentQuery.networkId, []],
      startDate: [this.currentQuery.startDate
        ? formatDate(this.currentQuery.startDate,'yyyy-MM-dd', 'fr')
        : '', []],
      endDate: [this.currentQuery.endDate
        ? formatDate(this.currentQuery.endDate,'yyyy-MM-dd', 'fr')
        : '', []]
    });
  }

  onSubmit() {
    if (!this.filterForm.valid) {
      return false;
    }
    const query = {
      id: this.filterForm.controls.id.value,
      nomenclature: this.filterForm.controls.nomenclature.value,
      networkId: this.filterForm.controls.networkId.value,
      startDate: this.filterForm.controls.startDate.value,
      endDate: this.filterForm.controls.endDate.value,
    };

    this.modalController.dismiss(query);
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
