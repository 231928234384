<ion-content>
  <app-header></app-header>
  <main role="main" class="">
    <div class="fr-container">
      <div class="fr-grid-row overflow-hidden">
        <div id="content" class="fr-col-12 fr-pl-4v">
          <div class="fr-container--fluid fr-mt-2w">
            <h3 class="fr-text--lead fr-mb-4v">Faits signalés</h3>
            <div class="fr-grid-row fr-grid-row--gutters">
              <div class="fr-col-6">
                <button (click)="onFilterClick()"class="fr-btn fr-btn--secondary fr-fi-filter-line fr-btn--icon-left fr-btn--full">Filtrer</button>
              </div>
              <div class="fr-col-6">
                <a class="fr-btn fr-fi-add-line fr-btn--icon-left fr-btn--full" replaceUrl="true" routerLink="/fait/ajout">Saisir un fait</a>
              </div>

              <div class="fr-col-12 fr-p-0">
                <div class="fr-table fr-table--bordered fr-table--condensed fr-table--spaced fr-table--no-caption fr-table--fit fr-mb-0">
                  <table>
                    <caption>Liste des faits</caption>
                    <thead>
                      <tr>
                        <th class="" scope="col">
                          <span class="mobile-version">N°</span>
                          <span class="desktop-version">N° du fait</span>
                        </th>
                        <th class="fr-th--full" scope="col">
                          <span class="mobile-version">Type</span>
                          <span class="desktop-version">Type de fait</span>
                        </th>
                        <th class="fr-th--full" scope="col">
                          <span class="mobile-version">Date</span>
                          <span class="desktop-version">Date du fait</span>
                        </th>
                        <th class="fr-th--full"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="!hasFacts">
                        <td colspan="4">Aucun fait n'a été déclaré pour le moment.</td>
                      </tr>
                      <tr
                        *ngFor="let fact of facts$ | async | paginate: { itemsPerPage: limit, currentPage: page, totalItems: factCount$ | async }">
                        <td class="fit">{{ fact.id }}</td>
                        <td class="ellipsis">{{ nomenclatures[fact.nomenclature].label }}</td>
                        <td class="fit">{{ fact.createdAt | date:'dd/MM/yy' }}</td>
                        <td class="fit">
                          <em (click)="onDeleteFact(fact)" class="fr-btn fr-btn--sm fr-btn--secondary fr-icon-delete-line fr-icon--sm fr-mr-2v"></em>
                          <em (click)="onUpdateFact(fact)" class="fr-btn fr-btn--sm fr-btn--secondary fr-icon-pencil-fill fr-icon--sm"></em>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="fr-col-12">
                <nav role="navigation" class="fr-pagination fr-center" aria-label="Pagination">
                  <pagination-template #p="paginationApi" (pageChange)="onPageChanged($event)">
                    <ul class="fr-pagination__list fr-center">
                      <li *ngIf="!p.isFirstPage()">
                        <button (click)="p.previous()"
                          class="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg-label">
                          Préc.
                        </button>
                      </li>
                      <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <button *ngIf="p.getCurrent() !== page.value" class="fr-pagination__link" (click)="p.setCurrent(page.value)">
                          <span>{{ page.label }}</span>
                        </button>
                        <button *ngIf="p.getCurrent() === page.value" class="fr-pagination__link" aria-current="page">
                          <span>{{ page.label }}</span>
                        </button>
                      </li>
                      <li *ngIf="!p.isLastPage()">
                        <button class="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg-label pagination-next"
                          (click)="p.next()" [class.disabled]="p.isLastPage()">
                          Suiv.
                        </button>
                      </li>
                    </ul>
                  </pagination-template>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</ion-content>