<app-header></app-header>
<ion-content>
  <main role="main" class="main">
    <div class="fr-container">
      <div class="fr-grid-row fr-grid-row--center">
        <div class="fr-col-12 fr-mt-6w">
          <div class="fr-callout fr-callout--max-height">
            <p class="fr-callout__text">
              Bienvenue sur App'ISIS, l'application de saisie des faits
              d'insécurité dans les transports en commun.
              <br /><br />
              Rapide, simple et sécurisée, laissez-vous guider pour saisir un
              fait d'insécurité que vous avez constaté ou qui vous a été signalé.
            </p>
            <app-login-form></app-login-form>
          </div>
        </div>
      </div>
    </div>
  </main>
</ion-content>