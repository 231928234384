import { FactGood } from './fact-good.model';
import { FactPerson } from './fact-person.model';

export class Fact {
  id: number;
  uuid: string;
  date: Date;
  time: Date;
  networkId: number;
  town: string;
  transport: string;
  stop: string;
  line: string;
  priorityZone: boolean;
  nomenclature: string;
  cause: string;
  serviceImpact: boolean;
  offerModification: boolean;
  departmentIntervention: boolean;
  policeIntervention: boolean;
  emergencyIntervention: boolean;
  complaint: boolean;
  weapon: boolean;
  persons: FactPerson[];
  goods: FactGood[];

  constructor(obj: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
