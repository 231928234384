import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Fact } from 'src/app/store/fact/fact.model';
import { environment as env } from '../../../environments/environment';
import { FactQuery } from '../models/fact-query.model'

@Injectable({
  providedIn: 'root'
})
export class FactService {
  constructor(private httpClient: HttpClient) { }

  public get(query: FactQuery): Observable<any> {
    let params = new HttpParams();
    Object.keys(query).forEach(key => {
      if (key && key !== 'type') {
        params = params.append(key, query[key]);
      }
    });

    return this.httpClient.get(`${env.apiUrl}/facts`, { params });
  }

  public create(fact: Fact): Observable<any> {
    return this.httpClient.post(`${env.apiUrl}/facts`, this.toDto(fact));
  }

  public update(fact: Fact): Observable<any> {
    return this.httpClient.put(`${env.apiUrl}/facts/${fact.uuid}`, this.toDto(fact));
  }

  public delete(fact: Fact): Observable<any> {
    return this.httpClient.delete(`${env.apiUrl}/facts/${fact.uuid}`);
  }

  getNomenclature() {
    return {
      "1": {
        label: "Atteintes aux personnes",
        example: "Atteintes physiques, sexuelles et verbales, vols avec violences et/ou menace…"
      },
      "2": {
        label: "Atteintes aux biens",
        example: "Vols sans violence ni menace, vols avec effraction, dégradations/dommages…"
      },
      "3": {
        label: "Atteintes visant à perturber le service de transport",
        example: "Entraves à la circulation, manipulation des équipements, bagages abandonnés…"
      },
      "4": {
        label: "Atteintes à la tranquillité",
        example: "Comportements inappropriés , rixes/bagarres, vente à la sauvette, stupéfiants…"
      },
      "A": {
        label: "Atteintes à l'intégrité physique ou non physique",
      },
      "A1": {
        label: "Atteintes volontaires à l'intégrité physique",
        label2: "Coups et blessures, crachats sur une personne, visées laser dans les yeux…",
        example: "Coups et blessures, crachats sur une personne, visées laser dans les yeux…",
        types: ['1', 'A', 'A1', '#']
      },
      "A11": {
        label: "Jets de projectiles avec victime(s)",
        example: "Jets de pierre, de ballast, de bouteille…",
        types:  ['1', 'A', 'A1', 'A11']
      },
      "A2": {
        label: "Atteintes volontaires non physiques",
        example: "Insultes, injures, menaces, outrages à agent…",
        types: ['1', 'A', 'A2', '']
      },
      "B": {
        label: "Atteintes sexuelles et sexistes",
      },
      "B1": {
        label: "Viols ou tentatives de viol",
        types: ['1', 'B', 'B1', '']
      },
      "B2": {
        label: "Autres atteintes sexuelles avec contact",
        example: "Attouchements sexuels, agissements de frotteurs…",
        types: ['1', 'B', 'B2', '']
      },
      "B3": {
        label: "Atteintes sexuelles et sexistes sans contact",
        label2: "Outrages sexistes non verbalisés, exhibition, voyeurisme…",
        example: "Outrages sexistes non verbalisés, exhibition, voyeurisme, harcèlement…",
        types: ['1', 'B', 'B3', '#']
      },
      "B31": {
        label: "Outrages sexistes verbalisés",
        example: "Sifflements, gestes/bruits obscènes, propositions sexuelles, questions intrusives/commentaires dégradants, suivre une personne de manière insistante…",
        types: ['1', 'B', 'B3', 'B31']
      },
      "C": {
        label: "Vols accompagnés de violence et/ou menaces",
        example: "Vols à l'arraché…",
        types: ['1', 'C', '', '']
      },
      "D": {
        label: "Atteintes aux biens sans atteintes aux personnes",
      },
      "D1": {
        label: "Vols simples / Vols sans effraction",
        example: "Vols à la tire, agissemements des pickpockets, vols à l'étalage, vols de matériel sans effraction…",
        types: ['2', 'D1', '#', '']
      },
      "D2": {
        label: "Dégradations / Dommages",
        label2: "Autres atteintes aux biens",
        example: "Destructions, déprédations…",
        example2: "Destructions, dégradations…"
      },
      "D21": {
        label: "Tags / Graffitis",
        example: "Tags, graffs, graffitis, gravures, fresques…",
        types: ['2', 'D2', 'D21', '']
      },
      "D22": {
        label: "Jets de projectiles avec bris sans victimes",
        example: "Jet de pierre, de ballast, de bouteille…",
        types: ['2', 'D2', 'D22', '']
      },
      "D23": {
        label: "Incendies",
        example: "Incendies de poubelle, de devanture…",
        types: ['2', 'D2', 'D23', '']
      },
      "D3": {
        label: "Vols avec effraction",
        example: "Vols d'un bien après avoir forcé la serrure…",
        types: ['2', 'D1', 'D3', '']
      },
      "E": {
        label: "Atteintes visant à perturber le service de transport",
      },
      "E1": {
        label: "Entraves à la circulation des véhicules",
        label2: "Personnes sur les voies, dépôts d'objets, voitures garées devant le bus, jets de projectiles sans bris ni victime, visées laser…",
        example: "Personnes sur les voies, dépôts d'objets, voitures garées devant le bus, jets de projectiles sans bris ni victime, visées laser…",
        types: ['3', 'E1', '#', '']
      },
      "E11": {
        label: "Jets de projectiles sans bris ni victime",
        example: "Jets de pierre, de ballast, de bouteille…",
        types: ['3', 'E1', 'E11', '']
      },
      "E12": {
        label: "Visées laser",
        example: "Utilisation de faisceau laser pour gêner autrui…",
        types: ['3', 'E1', 'E12', '']
      },
      "E2": {
        label: "Manipulation des équipements",
        example: "Faire obstacle à la fermeture des portes, ouvrir les portes d'accès après le signal de départ, actionner le signal d'alarme et les purges de portes sans motif légitime…",
        types: ['3', 'E2', '', '']
      },
      "E3": {
        label: "Non-respect des consignes de vigilance",
        example: "Bagages ou colis délaissés, fausses alertes…",
        types: ['3', 'E3', '', '']
      },
      "F": {
        label: "Atteintes d'ordre comportemental",
        types: ['4', 'F', '', '']
      },
      "F1": {
        label: "Actes contraires aux règles de police des transports",
        example: "Cracher, uriner, souiller les espaces, troubler la tranquillité d'autrui par des bruits ou des tapages, être en état d'ivresse manifeste, fumer en dehors des espaces autorisés, porter ou transporter une arme à feu, introduire un aminal sans autorisation…",
        types: ['4', 'F1', '', '']
      },
      "F2": {
        label: "Rixes",
        example: "Bagarres, altercations…",
        types: ['4', 'F2', '', '']
      },
      "F3": {
        label: "Infractions liées à la vente illicite",
        example: "Vente à la sauvette, vente et/ou consommation de produits stupéfiants…",
        types: ['4', 'F3', '', '']
      },
    }
  }

  private toDto(fact: Fact) {
    return {
      date: fact.date,
      time: fact.time,
      networkId: this.toNumber(fact.networkId),
      town: fact.town,
      transport: fact.transport,
      stop: fact.stop,
      line: fact.line,
      priorityZone: fact.priorityZone,
      nomenclature: fact.nomenclature,
      cause: fact.cause,
      serviceImpact: fact.serviceImpact,
      offerModification: fact.offerModification,
      departmentIntervention: fact.departmentIntervention,
      policeIntervention: fact.policeIntervention,
      emergencyIntervention: fact.emergencyIntervention,
      complaint: fact.complaint,
      weapon: fact.weapon,
      persons: fact.persons,
      goods: fact.goods,
    }
  }

  private toNumber(value: any) {
    return typeof value === 'string'
      ? parseInt(value)
      : value;
  }
}

