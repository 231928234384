<ion-content>
  <main class="modal" role="main">
    <div class="fr-container--fluid fr-mb-2w fr-mb-md-0 sticky">
      <header role="banner" class="fr-header" aria-label="En-tête">
        <div class="fr-container">
          <nav class="fr-nav submenu submenu--top" role="navigation" aria-label="Menu secondaire"
            data-fr-js-navigation="true">
            <div class="fr-grid-row fr-mx-n1w">
              <div class="fr-col-12 fr-px-1w">
                <div class="submenu-logo">
                  <div class="fr-col">
                    <div class="fr-text--bold fr-py-4v fr-text--lead">Ajout d'un bien</div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
    <div class="fr-container" style="height: max-content">
      <div class="fr-grid-row overflow-hidden">
        <div class="fr-col">
          <div class="fr-container--fluid">
            <form *ngIf="createForm" [formGroup]="createForm" novalidate>

              <div class="fr-select-group">
                <label class="fr-label" for="quality">Qualité du bien*</label>
                <select (change)="onChangeQuality($event.target.value)" class="fr-select" id="quality" name="quality"
                  formControlName="quality">
                  <option value="Bien de l'entreprise">Bien de l'entreprise (installation fixe, matériel roulant…)</option>
                  <option value="Bien privé">Bien privé (du voyageur, commerce, véhicule…)</option>
                  <option value="Bien de l'agent">Bien de l'agent</option>
                  <option value="Autre">Autre (mobilier urbain…)</option>
                  <option value="Inconnu">Je ne sais pas</option>
                </select>
                <p *ngIf="isSubmitted && createForm.controls?.quality?.errors?.required" class="fr-error-text">
                  La qualité du bien est obligatoire.
                </p>
              </div>

              <div *ngIf="isEnterprise" class="fr-select-group">
                <label class="fr-label" for="type">Type*</label>
                <select class="fr-select" id="type" name="type" formControlName="type">
                  <option value="Installation fixe">Installation fixe</option>
                  <option value="Matériel roulant">Matériel roulant</option>
                </select>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="fr-container--fluid fr-mb-md-0 fixed">
      <header role="banner" class="fr-header--bottom" aria-label="Pied de page">
        <div class="fr-container">
          <nav class="fr-nav submenu submenu--bottom" aria-label="Menu d'actions">
            <div class="fr-grid-row fr-mx-n1w fr-py-2w">
              <div class="fr-col fr-px-1w">
                <button (click)="onDismiss()" class="fr-btn fr-btn--secondary fr-btn--sm fr-btn--full">Annuler</button>
              </div>
              <div class="fr-col fr-px-1w">
                <button (click)="onSubmit()" type="submit" class="fr-btn fr-btn--sm  fr-btn--full">Ajouter</button>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  </main>
</ion-content>