<div class="inner-content">
  <div class="fr-modal__body">
    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Besoin d'aide ?
      </h1>
      <p>Pour toute demande d'assistance dans l'utilisation d'App'ISIS, veuillez contacter votre responsable.</p>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Fermer</button>
        </li>
      </ul>
    </div>
  </div>
</div>