import { formatDate } from "@angular/common";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

export class Validator {
  public static required(errorMessage: string) {
    return (control: UntypedFormControl) => {
      if (control.value) {
        return null;
      }
      return {
        required: errorMessage
      };
    };
  }

  public static lessThanTodayDate(errorMessage: string) {
    return (control: UntypedFormControl) => {
      if (!control.value) {
        return null;
      }
      const today = formatDate(new Date(), 'yyyy-MM-dd', 'fr');
      const date = formatDate(control.value, 'yyyy-MM-dd', 'fr');
      if (date <= today) {
        return null;
      }
      return {
        lessThanTodayDate: errorMessage
      };
    };
  }

  public static lessThanTodayTime(errorMessage: string) {
    return (formGroup: UntypedFormGroup) => {
      const todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'fr');
      const date = formatDate(formGroup.get('date').value, 'yyyy-MM-dd', 'fr');
      if (todayDate != date) {
        return null;
      }
      const todayTime = formatDate(new Date(), 'HH:mm', 'fr');
      const time = formGroup.get('time').value;
      if (time <= todayTime) {
        return null;
      }
      return {
        lessThanTodayTime: errorMessage
      };
    };
  }

  public static isInteger(errorMessage: string) {
    return (control: UntypedFormControl) => {
      if (!control.value) {
        return null;
      }
      if (Number.isInteger(parseInt(control.value))) {
        return null;
      }
      return {
        isInteger: errorMessage
      };
    };
  }
}