<div class="fr-container fr-container-relative fr-pb-7v fr-pt-8v">
  <div class="no-container fr-py-4v background-blue-ecume-main-400"></div>
  <nav role="navigation" class="fr-breadcrumb dark fr-my-0" aria-label="vous êtes ici :">
    <button class="fr-breadcrumb__button" aria-expanded="false" aria-controls="breadcrumb-1">Voir le fil d’Ariane</button>
    <div class="fr-collapse" id="breadcrumb-1">
      <ol class="fr-breadcrumb__list">
        <li *ngFor="let breadcrumb of breadcrumbs; let last = last;">
          <a *ngIf="!last" class="fr-breadcrumb__link" href="{{ breadcrumb.url }}">{{ breadcrumb.label }}</a>
          <a *ngIf="last" class="fr-breadcrumb__link" aria-current="page">{{ breadcrumb.label }}</a>
        </li>
      </ol>
    </div>
  </nav>
</div>